<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g dnone" id="addDeviceToggle">
        <div class="card card-body">
            <div class="form-group">
                <label for="">إسم الجهاز</label>
                <input type="text" class="form-control" name="title" required="" id="" aria-describedby="helpId" placeholder="" v-model="title">
            </div>
            <div class="form-group">
                <label for="">الرقم التسلسلي للجهاز (Serial Number)</label>
                <input type="text" class="form-control" required="" name="serial_number" id="" aria-describedby="helpId" placeholder="" v-model="serial_number">
                <small id="helpId" class="form-text text-muted">مثال: A902102003333</small>
            </div>
            <div class="form-group">
                <label for="">استعمال الجهاز لــ</label>
                <select class="form-control bg-white" style="cursor: pointer;" name="type" id="" v-model="type">
                    <option value="students">الطلاب</option>
                    <option value="teachers">المعلمين</option>
                </select>
            </div>
            <div class="col-12 text-center">
                <button class="btn btn-primary" @click="add()">
                    إضافة الجهاز
                </button>
            </div>
            <br>
            <div class="col-12 text-center">
                <img src="https://cdn-static.brmja.com/storage/uploads/images/6251652c515d0136419250.jpg" style="width: 60%; border: solid 2px red">
            </div>
        </div>
    </div>
    <div class="col-12 g">
        <div class="card">
            <div class="card-header">
                <h4>
                    اجهزة البصمة <button class="btn btn-primary" style="padding: 5px; padding-right: 7px; padding-left: 7px" @click="addDeviceToggle()"><i class="fa fa-plus"></i> اضافة جهاز</button>
                    &nbsp;
                    <a target="_blank" class="btn btn-success" href='https://devices.tahdir.net'>
                        <i class="fa fa-shopping-cart"></i>
                        شراء اجهزة بصمة
                    </a>
                    &nbsp;
                    <button class="btn btn-primary btn-sm" style="border-radius:0px"  v-b-modal.students>
                        <i class="fa fa-users"></i>
                        نقل الطلاب لاجهزة البصمة
                    </button>
                    <button class="btn btn-primary btn-sm" style="border-radius:0px;background: #3FA2F6 !important"  v-b-modal.teachers>
                        <i class="fa fa-users"></i>
                        نقل المعلمين لاجهزة البصمة
                    </button>
                    &nbsp;
                    <button class="btn btn-outline-danger" v-if="pending > 0">
                        <img :src="require('@/assets/images/loading.svg')" style="width: 16px;" alt="">
                        جاري معالجة 
                        {{ pending }} بصمة...
                    </button>
                    
                </h4>
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                            <th>
                                العنوان
                            </th>
                            <th>
                                العمليات المعلقة
                            </th>
                            <th>
                                الرقم التسلسلي
                            </th>
                            <th>
                                الموديل
                            </th>
                            <th>
                                نوع الجهاز
                            </th>
                            <th>
                                الحالة
                            </th>
                            <th>
                                المستخدمين
                            </th>
                            <th>
                                البصمات
                            </th>
                            <th>
                                بصمات الوجه
                            </th>
                            <th>
                                السجلات
                            </th>
                            <th>
                                IP
                            </th>
                            <th>
                                خيارات
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="device in devices" :key="device._id">
                                <td>
                                    {{ device.title }}
                                </td>
                                <td>
                                    <span v-if="device.recordsx == 0">0</span>
                                    <span v-if="device.recordsx > 0" class="text-danger">
                                        <img :src="require('@/assets/images/loading.svg')" style="width: 16px;" alt="">
                                        {{ device.recordsx }}
                                        <u href="#" @click="deleteOrders(device.serial_number)">الغاء</u>
                                        </span>
                                </td>
                                <td>
                                    {{ device.serial_number }}
                                </td>
                                <td>
                                    {{ device.device_name }}
                                </td>
                                <td>
                                    {{ device.type == 'students' ? 'للطلاب' : 'للمعلمين' }}
                                </td>
                                <td>
                                    <span v-if="!device.ready" class="text-success">
                                        <img :src="require('@/assets/images/up.gif')" title="يقوم برفع البيانات" style="width: 16px;" alt="">
                                    </span>
                                    <span v-html="device.last_update"></span>
                                    <template v-if="device.ex_enable == 'yes'">
                                        <br>
                                        <button class="btn btn-sm btn-outline-primary" style="padding: 2px; border-radius: 0px" v-if="timeexnow(device)">
                                            <i class="fa fa-user"></i>
                                             للاستئذان الآن
                                        </button>
                                    </template>
                                </td>
                                <td>
                                    {{ device.users }}
                                </td>
                                <td>
                                    {{ device.fingerprints }}
                                </td>
                                <td>
                                    {{ device.faces }}
                                </td>
                                <td>
                                    {{ device.records }}
                                </td>
                                <td>
                                    {{ device.ip }}
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-primary" v-b-modal.options @click="current = device">
                                        <i class="fa fa-cogs"></i>
                                        خيارات
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="options" title="خيارات" hide-footer size="sm">
        <div class="row">
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-default" v-b-modal.upload-reports>
                    <i class="fa fa-upload"></i>
                    رفع تقارير من الجهاز
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-success " v-b-modal.logs @click="getlogs()">
                    <i class="fa fa-clock-o"></i>
                    السجلات المرفوعة حديثاً
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-primary " @click="syncUpload()" style="background: #596FB7 !important; border: none">
                    <i class="fa fa-upload"></i>
                    مزامنة البيانات رفع
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-info " @click="syncDownload()" style="background: #AC87C5 !important; border: none">
                    <i class="fa fa-download"></i>
                    مزامنة البيانات تنزيل
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-danger btn-block btn-info " @click="fromold()" style="background: #3795BD !important; border: none">
                    <i class="fa fa-reply"></i>
                    نقل البصمات من النظام القديم
                </button>
            </div>
            <div class="col-12 g dnone">
                <button class="btn btn-danger btn-block btn-secondary " v-b-modal.ex style="background: #87A2FF !important; border: none">
                    <i class="fa fa-sign-out"></i>
                    تعيين كجهاز للإستئذان
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-danger " @click="deleteDevice(current._id)">
                    <i class="fa fa-trash"></i>
                    حذف الجهاز
                </button>
            </div>
        </div>
    </b-modal>
    <b-modal id="upload-reports" title="خيارات" hide-footer size="sm">
        <h4>
            رفع تقرير يوم معين
        </h4>
        <div class="form-group">
          <label for="">التاريخ</label>
          <input type="date"
            class="form-control" v-model="from">
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="uploadSingle()">
                <i class="fa fa-upload"></i>
                رفع التقرير
            </button>
        </div>
        <hr>
        <h4>
            رفع تقرير فترة
        </h4>
        <div class="form-group">
          <label for="">من</label>
          <input type="date"
            class="form-control" v-model="from">
        </div>
        <div class="form-group">
          <label for="">الى</label>
          <input type="date"
            class="form-control" v-model="to">
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="uploadBetween()">
                <i class="fa fa-upload"></i>
                رفع التقرير
            </button>
        </div>
    </b-modal>
    <b-modal id="students" title="نقل الطلاب لجهاز البصمة" hide-footer size="lg">
        <div class="g">
        اختر الطلاب <a href="javascript:;" @click="selected_students_devices = students"><u>اختيار الكل</u></a>
        </div>
        <div style="width: 100%; border: solid 2px #333; padding: 5px; overflow-y: scroll; height: 150px;">
            <div class="form-check" v-for="student in students" :key="student._id">
            <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected_students_devices" :value="student">
                {{ student.name }}
            </label>
            </div>
        </div>
        <br>
        <p>اختر الجهاز المراد النقل له</p>
        <table class="table table-sm table-hover table-bordered">
        <thead>
            <th>
                الجهاز
            </th>
            <th>
                نقل اليه
            </th>
        </thead>
        <tbody>
            <tr v-for="n in devices" :key="n._id">
                <td>
                    {{ n.title }}
                </td>
                <td>
                    <button class="btn btn-success btn-sm" @click="movetodevices(n.serial_number)" v-if="!objloading[n._id]">
                        <i class="fa fa-download"></i> نقل الى الجهاز
                    </button>
                    <span v-if="objloading[n._id]">جاري النقل...</span>
                </td>
            </tr>
        </tbody>
    </table>
    </b-modal>
    <b-modal id="teachers" title="نقل المعلمين لجهاز البصمة" hide-footer size="lg">
        <div class="g">
        اختر المعلمين <a href="javascript:;" @click="selected_teachers_devices = teachers"><u>اختيار الكل</u></a>
        </div>
        <div style="width: 100%; border: solid 2px #333; padding: 5px; overflow-y: scroll; height: 150px;">
            <div class="form-check" v-for="student in teachers" :key="student._id">
            <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected_teachers_devices" :value="student">
                {{ student.name }}
            </label>
            </div>
        </div>
        <br>
        <p>اختر الجهاز المراد النقل له</p>
        <table class="table table-sm table-hover table-bordered">
        <thead>
            <th>
                الجهاز
            </th>
            <th>
                نقل اليه
            </th>
        </thead>
        <tbody>
            <tr v-for="n in devices" :key="n._id">
                <td>
                    {{ n.title }}
                </td>
                <td>
                    <button class="btn btn-success btn-sm" @click="movetodevices2(n.serial_number)" v-if="!objloading[n._id]">
                        <i class="fa fa-download"></i> نقل الى الجهاز
                    </button>
                    <span v-if="objloading[n._id]">جاري النقل...</span>
                </td>
            </tr>
        </tbody>
    </table>
    </b-modal>
    <b-modal id="logs" title="خيارات" hide-footer size="lg">
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        الهوية
                    </th>
                    <th>
                        التوقيت
                    </th>
                    <th>
                        الجهاز
                    </th>
                </thead>
                <tbody>
                    <tr v-for="log in logs" :key="log.time + log.number">
                        <td>
                            {{ log.number }}
                        </td>
                        <td>
                            {{ log.time }}
                        </td>
                        <td>
                            {{ log.serial_number }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
    <b-modal id="ex" title="تعيين كجهاز استئذان" hide-footer>
        <div class="alert alert-info text-center g">
            <span class="text-primary">
                سيتم تعيين هذا الجهاز للطلاب للإستئذان في الوقت المحدد.
            </span>
        </div>
        <div class="form-group">
            <label for="">تفعيل الاستئذان لهذا الجهاز</label>
            <select class="form-control" v-model="current.ex_enable">
            <option value="yes">مفعلة</option>
            <option value="no">معطلة</option>
            </select>
        </div>
        <div class="form-group">
          <label for="">وقت بداية الاستئذان</label>
          <input type="time"
            class="form-control" v-model="current.ex_from">
        </div>
        <div class="form-group">
          <label for="">وقت نهاية الاستئذان</label>
          <input type="time"
            class="form-control" v-model="current.ex_to">
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="exSave()">
                <i class="fa fa-save"></i>
                حفظ التعديلات
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput } from "bootstrap-vue";
export default {
  components: {
    // Layouts
    BModal,BFormInput},
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            devices: [],
            selected_students_devices: [],
            selected_teachers_devices: [],
            user: JSON.parse(localStorage.getItem('user')),
            type: 'students',
            serial_number: '',
            title: "",
            current: {},
            pending: 0,
            students: [],
            objloading: {},
            teachers: [],
            from: new Date().toISOString().split("T")[0],
            to: new Date().toISOString().split("T")[0],
            interval: null,
            logs: []
        }
    },
    created(){
        if(!checkPer("devices")){
            this.$router.push('/NotPermitted')
            return false;
        }
        this.getDevices();
        var g = this;
        g.interval = setInterval(() => {
            g.getDevices();
        }, 5000);
        $.post(api + '/user/students/list', {
            jwt: this.user.jwt,
            q: "",
            page: 1,
            perpage: 9999
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.students = r.response
            }
        }).fail(function(){
            g.loading = false;
        })
        $.post(api + '/user/teachers/list', {
            jwt: this.user.jwt,
            q: "",
            page: 1,
            perpage: 9999
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.teachers = r.response
            }
        }).fail(function(){
            g.loading = false;
        })
    },
    beforeDestroy(){
        if(this.interval){
            clearInterval(this.interval)
        }
    },
    methods: {
        timeexnow(device){
            var date = new Date().toISOString().split("T")[0];
            if(new Date() >= new Date(date + ' ' + device?.ex_from)){
                if(new Date() <= new Date(date + ' ' + device?.ex_to)){
                    return true;
                }
            }
            return false;
        },
        exSave(){
            var g = this;
            $.post(api + '/user/devices/save-ex', {
                jwt: this.user.jwt,
                id: g.current._id,
                enable: g.current.ex_enable,
                from: g.current.ex_from,
                to: g.current.ex_to
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, r.status)
                }else{
                    g.getDevices()
                    alert("تم الحفظ بنجاح", 100)
                    $("#ex___BV_modal_header_ > button").click()
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        getDevices(){
            var g = this;
            $.post(api + '/user/devices/all', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.devices = r.response.map(function(a){
                        if(!a.ex_enable){
                            a.ex_enable = "no";
                            a.ex_from = "09:00";
                            a.ex_to = "11:00";
                        }
                        return a;
                    })
                    g.pending = r.pending
                }
            }).fail(function(){
            })
        },
        addDeviceToggle(){
            $("#addDeviceToggle").toggle("slow")
        },
        movetodevices(device_id){
            var g = this;
            g.objloading[device_id] = true;
            g.objloading = JSON.parse(JSON.stringify(g.objloading))
            $.post(api + '/user/devices/move-users-to-devices', {
                jwt: this.user.jwt,
                devices: [device_id],
                users: g.selected_students_devices.map(function(a){
                    return {
                        name: a.name,
                        number: a.number
                    }
                })
            }).then(function(r){
                g.objloading[device_id] = false;
                g.objloading = JSON.parse(JSON.stringify(g.objloading))
                if(r.status != 100){
                    alert(r.response)
                }else{
                    alert("تم بنجاح", 100)
                }
            }).fail(function(){
                g.objloading[device_id] = false;
                g.objloading = JSON.parse(JSON.stringify(g.objloading))
            })
        },
        movetodevices2(device_id){
            var g = this;
            g.objloading[device_id] = true;
            g.objloading = JSON.parse(JSON.stringify(g.objloading))
            $.post(api + '/user/devices/move-users-to-devices', {
                jwt: this.user.jwt,
                devices: [device_id],
                users: g.selected_teachers_devices.map(function(a){
                    return {
                        name: a.name,
                        number: a.number
                    }
                })
            }).then(function(r){
                g.objloading[device_id] = false;
                g.objloading = JSON.parse(JSON.stringify(g.objloading))
                if(r.status != 100){
                    alert(r.response)
                }else{
                    alert("تم بنجاح", 100)
                }
            }).fail(function(){
                g.objloading[device_id] = false;
                g.objloading = JSON.parse(JSON.stringify(g.objloading))
            })
        },
        add(){
            var g = this;
            $.post(api + '/user/devices/add', {
                jwt: this.user.jwt,
                title: g.title,
                serial_number: g.serial_number,
                type: g.type
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, r.status)
                }else{
                    g.getDevices()
                    g.addDeviceToggle()
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        deleteDevice(id){
            if(confirm("متاكد من حذف الجهاز نهائياً؟")){
                var g = this;
                $.post(api + '/user/devices/delete', {
                    jwt: this.user.jwt,
                    id: id
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response, r.status)
                    }else{
                        g.getDevices()
                        $("#options___BV_modal_header_ > button").click()
                    }
                }).fail(function(){
                    alert("حدث خطأ", 200)
                })
            }
        },
        fromold(){
            if(confirm("متاكد من نقل بصمات المستخدمين من النظام القديم؟")){
                var g = this;
                $.post(api + '/user/devices/get-from-old', {
                    jwt: this.user.jwt,
                    serial_number: this.current.serial_number
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response, r.status)
                    }else{
                        g.getDevices()
                        alert(r.response, r.status)
                        $("#options___BV_modal_header_ > button").click()
                    }
                }).fail(function(){
                    alert("حدث خطأ", 200)
                })
            }
        },
        getlogs(){
            var g = this;
            $.post(api + '/user/devices/logs', {
                jwt: this.user.jwt,
                sn: this.current.serial_number
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, r.status)
                }else{
                    g.logs = [];
                    r.response.forEach(function(a){
                        (JSON.parse(a.data)).forEach(function(b){
                            g.logs.push({
                                number: b.id,
                                time: b.time,
                                serial_number: a.serial_number
                            })
                        })
                    })
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        uploadSingle(){
            var g = this;
            $.post(api + '/user/devices/upload', {
                jwt: this.user.jwt,
                from: this.from + ' 01:00:00',
                to: this.from + ' 23:59:59',
                sn: this.current.serial_number
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, r.status)
                }else{
                    $("#upload-reports___BV_modal_header_ > button").click()
                    g.getDevices()
                    alert("تم ارسال العملية بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        uploadBetween(){
            var g = this;
            $.post(api + '/user/devices/upload', {
                jwt: this.user.jwt,
                from: this.from + ' 01:00:00',
                to: this.to + ' 23:59:59',
                sn: this.current.serial_number
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, r.status)
                }else{
                    $("#upload-reports___BV_modal_header_ > button").click()
                    g.getDevices()
                    alert("تم ارسال العملية بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        syncUpload(){
            var g = this;
            $.post(api + '/user/devices/sync-upload', {
                jwt: this.user.jwt,
                sn: this.current.serial_number
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, r.status)
                }else{
                    g.getDevices()
                    alert("تم ارسال العملية بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        syncDownload(){
            var g = this;
            $.post(api + '/user/devices/sync-download', {
                jwt: this.user.jwt,
                sn: this.current.serial_number
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, r.status)
                }else{
                    g.getDevices()
                    alert("تم ارسال العملية بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        deleteOrders(sn){
            if(confirm("متاكد من الغاء الاوامر المعلقة لهذا الجهاز؟")){
                var g = this;
                $.post(api + '/user/devices/cancel-orders', {
                    jwt: this.user.jwt,
                    sn: sn
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response, r.status)
                    }else{
                        g.getDevices()
                        alert("تم الالغاء بنجاح", 100)
                    }
                }).fail(function(){
                    alert("حدث خطأ", 200)
                })
            }
        }
    }
}
</script>

<style>

</style>